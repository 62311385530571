<!--
 * @Author: your name
 * @Date: 2021-11-11 18:15:16
 * @LastEditTime: 2021-11-11 18:32:09
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \template_page\src\App.vue
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { routerM } from "./router/routerM";
import { routerPC } from "./router/routerPC";
export default {
  data() {
    return {
      active: 0,
      clientWidth: 0
    };
  },
  created() {
    
    this.clientWidth = document.body.clientWidth;
    window.addEventListener("resize", () => {
      if (document.body.clientWidth <= 1200) {
        console.log('设置手机路由')
        this.setRem();
        this.$router.addRoutes(routerM)
        // console.log('设置路由m', routerM)
        if (this.clientWidth > 1200) {
          location.reload()
        }

      } else {
        this.$router.addRoutes(routerPC)
        // console.log('设置路由pc', routerPC)
        if (this.clientWidth <= 1200) {
          location.reload()
        }
      }
    });

  },
  methods: {
    setRem() {
      // console.log("设置移动");
      // var whdef = 100 / 750; // 表示750的设计图,使用100PX的默认值
      // var bodyWidth = document.body.clientWidth; // 当前窗口的宽度
      // var rem = bodyWidth * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
      // document.getElementsByTagName("html")[0].style.fontSize = rem + "px";
      var docEl = document.documentElement,
        resizeEvt = "orientationchange" in window ? "orientationchange" : "resize",
        recalc = function () {
          var clientWidth = docEl.clientWidth;
          docEl.style.fontSize = 100 * (clientWidth / 750) + "px";
        };
      if (!document.addEventListener) return;
      window.addEventListener(resizeEvt, recalc, false);
      document.addEventListener("DOMContentLoaded", recalc, false);
    },
    //pc端
    setRemPC() {
      console.log("设置PC");
      var whdef = 100 / 1920; // 表示1920的设计图,使用100PX的默认值
      var bodyWidth = document.body.clientWidth; // 当前窗口的宽度
      var rem = bodyWidth * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
      console.log(rem, "resss");
      document.getElementsByTagName("html")[0].style.fontSize = rem + "px";
    }
  }
};
</script>
<style lang="scss">
@import url("./assets/baseStyle/common.css");
</style>
