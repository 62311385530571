import Vue from "vue";
import Router from "vue-router";
import { routerM } from "./routerM";
import { routerPC } from "./routerPC";

Vue.use(Router);
// 是否为手机端
const ISMOBILE = function () {
  // let flag = navigator.userAgent.match(
  //   /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  // );
  // return flag;
  if (document.body.clientWidth <= 1200) {
    return true;
  } else {
    return false;
  }
};

// 创建路由实例
let router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  fallback: false,
  scrollBehavior: () => ({
    y: 0,
  }),
  // 这里判断后选择使用移动端的路由还是pc端的路由
  routes: ISMOBILE() ? routerM : routerPC,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // 如果之前有保存滚动位置，则恢复到之前保存的位置
      return savedPosition;
    } else {
      // 否则，默认滚动到页面顶部
      return { x: 0, y: 0 };
    }
  },
});
console.log('进入路由')
window.addEventListener("resize", () => {
  console.log('屏幕尺寸变化')
  if (document.body.clientWidth <= 1200) {
    router = new Router({
      mode: "hash",
      base: process.env.BASE_URL,
      fallback: false,
      // 这里判断后选择使用移动端的路由还是pc端的路由
      routes: routerM,
      // routes: routerPC,
      scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
          // 如果之前有保存滚动位置，则恢复到之前保存的位置
          return savedPosition;
        } else {
          // 否则，默认滚动到页面顶部
          return { x: 0, y: 0 };
        }
      },
    });
  } else {
    router = new Router({
      mode: "hash",
      base: process.env.BASE_URL,
      fallback: false,
      // 这里判断后选择使用移动端的路由还是pc端的路由
      routes: routerPC,
      // routes: routerPC,
      scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
          // 如果之前有保存滚动位置，则恢复到之前保存的位置
          return savedPosition;
        } else {
          // 否则，默认滚动到页面顶部
          return { x: 0, y: 0 };
        }
      },
    });
  }
});
export default router;
