// routerM.js
export const routerPC = [
  {
    path: "/",
    component: () => import("@/views/pc/index.vue"),
    hidden: true,
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "Home",
        component: () => import("@/views/pc/home.vue"),
        meta: {
          title: "首页",
          icon: "home",
        },
      },
      {
        path: "protocol",
        name: "Protocol",
        component: () => import("@/views/pc/protocol.vue"),
        meta: {
          title: "协议",
          icon: "protocol",
        },
      },
      {
        path: "aboutUs",
        name: "AboutUs",
        component: () => import("@/views/pc/aboutUs.vue"),
        meta: {
          title: "关于我们",
          icon: "aboutUs",
        },
      },
      {
        path: "contactUs",
        name: "ContactUs",
        component: () => import("@/views/pc/contactUs.vue"),
        meta: {
          title: "联系我们",
          icon: "contactUs",
        },
      },
    ],
  },
];
